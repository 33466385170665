<template>
  <div class="page-container pa-0">
    <!-- <BaseBtn
      text="立即登錄發票"
      type="submit"
      color="primary"
      @click="routeTo('/invoiceLog')"
    /> -->
    <BaseBtn
      text="回到首頁"
      type="back"
      :isOutlined="true"
      color="primary"
      @click="routeTo('/home')"
    />
  </div>
</template>

<script>
import BaseBtn from "@/components/BaseBtn.vue";

export default {
  name: "PageBtnSet",
  components: {
    BaseBtn,
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    }
  }
};
</script>