<template>
  <div class="exchange page-container">
    <PageTitle title="兌換專區" />
    <div class="points">
      <div class="points_row total">
        <p class="points_text">總點數</p>
        <p class="points_text point">
          <span>{{ totalPoints }}</span> 點
        </p>
      </div>
      <div class="points_row">
        <p class="points_text">已兌換點數</p>
        <p class="points_text">{{ spentPoints }} 點</p>
      </div>
      <div class="points_row">
        <p class="points_text">累計登錄金額</p>
        <p class="points_text">NT$ {{ totalPrice }} 元</p>
      </div>
      <div class="points_history" @click="openPointHistory">點數使用歷程</div>
    </div>

    <div class="exchange_alert" v-if="isClose">
      獎項全面更新中，<br />新活動即將上線，敬請期待！
    </div>
    <div class="exchange_list" v-else>
      <div class="exchange_filter" v-if="false">
        <div class="exchange_text">
          於活動指定期間，購買 Asahi SUPER DRY，<br />單筆發票滿500元，即可開啟此區兌換遊戲機會。
        </div>
      </div>
      <!--      <div class="block">-->
      <!--        <div class="line"></div>-->
      <!--        <div class="exchange_list_item">-->
      <!--          <div class="exchange_list_item_img">-->
      <!--            <img :src="getVirtualReward().photo" />-->
      <!--          </div>-->
      <!--          <div class="exchange_list_item_content">-->
      <!--            <p class="exchange_list_item_title">{{ getVirtualReward().name }}</p>-->
      <!--            <div class="exchange_list_item_row">-->
      <!--              <p>使用點數</p>-->
      <!--              <p>-->
      <!--                <span>{{ getVirtualReward().points }}</span><span class="unit"> 點</span>-->
      <!--              </p>-->
      <!--            </div>-->
      <!--            <BaseBtn class="exchange_btn" :text="getVirtualReward().text" :isRounded="true" width="100%" height="35px"-->
      <!--              :color="getVirtualReward().color" @click="exchangeLottery()" />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="block" v-for="(item, i) in rewardsList" :key="i">
        <div class="line"></div>
        <div class="exchange_list_item">
          <div class="exchange_list_item_img">
            <img :src="item.photo" />
          </div>
          <div class="exchange_list_item_content">
            <p class="exchange_list_item_title">{{ item.name }}</p>
            <!-- <p
              class="exchange_list_item_detail"
              @click="openDetail(item)"
              v-if="i === 5"
            >
              獎項細節
            </p> -->
            <div class="exchange_list_item_row">
              <p>使用點數</p>
              <p>
                <span>{{ item.points }}</span
                ><span class="unit"> 點</span>
              </p>
            </div>
            <BaseBtn
              class="exchange_btn"
              :text="item.text"
              :isRounded="true"
              width="100%"
              height="35px"
              :color="item.color"
              :disabled="item.text !== '立即兌換'"
              @click="exchange(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="exchange_notice">
      贈品數量有限，兌完為止，以提出兌換申請的時間點為準。<br />
      臺灣朝日啤酒股份有限公司保有活動修改、終止、解釋之權利。
    </div>
    <PageBtnSet />
    <!-- Confirm Dialog -->
    <!-- 0 -->
    <Dialog
      class="exchange-dialog"
      :dialog="openDialogStep0"
      title="兌獎確認(抽獎)"
    >
      <div class="exchange-dialog_content">
        <p class="exchange-dialog_title">提醒：</p>
        <p>
          確認使用 <span>{{ nowItem.points }}點</span> 兌換<br />獎項：{{
            nowItem.name
          }}
        </p>
      </div>
      <BaseBtn text="確認兌換" :isRounded="true" @click="confirmExchange" />
      <BaseBtn
        text="取消兌換"
        :isRounded="true"
        :isOutlined="true"
        @click="cancelExchange"
      />
    </Dialog>
    <!-- 1 -->
    <Dialog class="exchange-dialog" :dialog="openDialogStep1" title="兌獎確認">
      <div class="exchange-dialog_content">
        <p class="exchange-dialog_title">提醒：</p>
        <p>
          確認使用 <span>{{ nowItem.points }}點</span> 兌換<br />獎項：{{
            nowItem.name
          }}
        </p>
      </div>
      <BaseBtn text="確認兌換" :isRounded="true" @click="confirmInfo" />
      <BaseBtn
        text="取消兌換"
        :isRounded="true"
        :isOutlined="true"
        @click="cancelExchange"
      />
    </Dialog>
    <!-- 2 -->
    <Dialog class="exchange-dialog" :dialog="openDialogStep2" title="資料確認">
      <div class="exchange-dialog_content">
        <p class="exchange-dialog_title">提醒：</p>
        <p>獎項會寄送至會員登錄之地址<br />請確認，您登錄的地址：</p>
      </div>
      <v-text-field
        v-model="address"
        solo
        flat
        light
        hide-details
        class="exchange_input"
      ></v-text-field>
      <BaseBtn text="確認兌換" :isRounded="true" @click="confirmExchange" />
    </Dialog>
    <!-- 3 -->
    <Dialog class="exchange-dialog" :dialog="openDialogStep3" title="兌換成功">
      <div class="exchange-dialog_content">
        <p class="exchange-dialog_title">提醒：</p>
        <p>
          獎項將於 3/11 後統整兌換數量，統一安排於 3/13-3/19 間寄送聯繫 <br />
          再請留意收件並簽收
          <!-- 獎項將於隔月月底前統整兌換數量後統一安排寄送 <br />再請留意收件並簽收 -->
        </p>
      </div>
      <BaseBtn text="關閉視窗" :isRounded="true" @click="closeConfirm" />
    </Dialog>
    <!-- 點數使用歷程 -->
    <Dialog
      class="exchange-dialog"
      :dialog="pointHistoryDialog"
      title="點數使用歷程"
    >
      <div class="point-history_list">
        <div
          v-for="(item, i) in pointsData.history"
          :key="i"
          class="point-history_item"
        >
          <p>{{ item.datetime }}</p>
          <div class="point-history_item_row">
            <p>使用點數</p>
            <p class="point-history_item_text">{{ item.points }}點</p>
          </div>
          <div class="point-history_item_row">
            <p>兌換商品</p>
            <p class="point-history_item_text">{{ item.reward }}</p>
          </div>
        </div>
      </div>
      <BaseBtn text="關閉" :isRounded="true" @click="closePointHistory" />
    </Dialog>
    <!-- 獎項細節 -->
    <Dialog
      class="exchange-dialog"
      :dialog="openDetailDialog"
      :title="nowItem.name"
    >
      <div class="exchange-dialog_content">
        <p>
          {{ nowItem.description }}
        </p>
      </div>
      <BaseBtn text="關閉" :isRounded="true" @click="closeDetail" />
    </Dialog>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue'
import PageBtnSet from '@/components/PageBtnSet.vue'
import BaseBtn from '@/components/BaseBtn.vue'
import Dialog from '@/components/Dialog.vue'

export default {
  name: 'Exchange',
  components: {
    PageTitle,
    PageBtnSet,
    BaseBtn,
    Dialog
  },
  data() {
    return {
      clicked: false,
      openDialogStep0: false,
      openDialogStep1: false,
      openDialogStep2: false,
      openDialogStep3: false,
      pointHistoryDialog: false,
      openDetailDialog: false,
      exchangeFilter: true,
      totalPrice: 0,
      totalPoints: 0,
      spentPoints: 0,
      invoiceData: [],
      pointsData: {},
      rewardsList: [],
      address: '',
      nowItem: {},
      testList: [
        { text: '立即兌換', color: 'primary' },
        { text: '點數不足', color: '#888' },
        { text: '已無庫存', color: '#888' }
      ],
      isClose: false
    }
  },
  created() {
    this.getdata()
    this.checkClose()
  },
  methods: {
    checkClose() {
      const now = new Date().getTime()
      this.isClose =
        new Date(process.env.VUE_APP_REWARD_CLOSE_DATE).getTime() <= now &&
        now <= new Date(process.env.VUE_APP_NEW_EVENT_DATE).getTime()
    },
    getVirtualReward() {
      const pointConfig = 2
      return {
        id: 0,
        photo: process.env.VUE_APP_BASEURL + 'images/lottery.png',
        name: 'ASAHI禮盒抽獎券',
        points: pointConfig,
        text:
          this.pointsData.remainPoints >= pointConfig ? '立即兌換' : '點數不足',
        color: this.pointsData.remainPoints >= pointConfig ? 'primary' : '#888'
      }
    },
    async getdata() {
      this.$store.commit('updateLoading', true)
      let url = process.env.VUE_APP_APIUrl + 'api/v1/users/get-user-token'
      let data = {
        lineToken: window.localStorage.getItem('userToken')
      }
      await this.axios
        .post(url, data)
        .then((res) => {
          window.localStorage.setItem('jwt', res.data.data.token)
          this.getInvoiceList()
          this.getPointsList()
          this.getUserInfo()
        })
        .catch((e) => {
          alert('系統異常')
          console.log(e)
          this.$store.commit('updateLoading', false)
        })
    },
    getUserInfo() {
      let url = process.env.VUE_APP_APIUrl + 'api/v1/auth/users/info'
      this.axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
          }
        })
        .then((res) => {
          console.log(res)
          this.address = res.data.data.address
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async verifyInvoice() {
      let url =
        process.env.VUE_APP_APIUrl + 'api/v1/auth/rewards/verify-user-invoice'
      await this.axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
          }
        })
        .then((res) => {
          console.log(res.data.data.invoiceEnough)
          if (res.data.data.invoiceEnough === true) {
            this.exchangeFilter = false
          }
        })
        .catch((e) => {
          //alert("系統異常");
          console.log(e)
        })
    },
    getRewardsList() {
      let url = process.env.VUE_APP_APIUrl + 'api/v1/rewards/list'
      this.axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
          }
        })
        .then((res) => {
          console.log(res.data.data)
          this.rewardsList = []
          let topAward = {}
          res.data.data.forEach((el) => {
            // 只放入該檔期的獎品
            if (el.id < process.env.VUE_APP_NEW_AWARD_ID) return

            const item = {
              ...el,
              text:
                el.points > this.pointsData.remainPoints
                  ? '點數不足'
                  : '立即兌換'
            }
            if (item.remainQty <= 0) item.text = '兌換完畢'
            item.color = item.text === '立即兌換' ? 'primary' : '#888'
            if (el.id === process.env.VUE_APP_TOP_AWARD_ID) {
              topAward = item
              return
            }
            this.rewardsList.push(item)
          })
          if (topAward.id) {
            this.rewardsList.unshift(topAward)
          }

          this.$store.commit('updateLoading', false)
        })
        .catch((e) => {
          alert('系統異常')
          console.log(e)
          this.$store.commit('updateLoading', false)
        })
    },
    getInvoiceList() {
      let url = process.env.VUE_APP_APIUrl + 'api/v1/auth/invoices/list'
      this.axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
          }
        })
        .then((res) => {
          console.log(res.data.data)
          this.invoiceData = res.data.data
          let sum = 0
          this.invoiceData.forEach((item) => {
            sum = sum + Number(item.price)
          })
          let internationalNumberFormat = new Intl.NumberFormat('en-US')
          this.totalPrice = internationalNumberFormat.format(sum)
        })
        .catch((e) => {
          alert('系統異常')
          console.log(e)
          this.$store.commit('updateLoading', false)
        })
    },
    getPointsList() {
      let url = process.env.VUE_APP_APIUrl + 'api/v1/auth/points/list'
      this.axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
          }
        })
        .then((res) => {
          console.log(res.data.data)
          this.pointsData = res.data.data
          let internationalNumberFormat = new Intl.NumberFormat('en-US')
          this.totalPoints = internationalNumberFormat.format(
            Number(this.pointsData.totalPoints)
          )
          this.spentPoints = internationalNumberFormat.format(
            Number(this.pointsData.spentPoints)
          )
          this.getRewardsList()
        })
        .catch((e) => {
          alert('系統異常')
          console.log(e)
          this.$store.commit('updateLoading', false)
        })
    },
    exchange(item) {
      this.nowItem = item
      console.log(this.nowItem)

      if (item.text === '立即兌換') {
        this.openDialogStep1 = true
        let url =
          process.env.VUE_APP_APIUrl + 'api/v1/auth/rewards/verify-user-points'
        let data = {
          rewardId: item.id
        }
        this.axios
          .post(url, data, {
            headers: {
              Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
            }
          })
          .then((res) => {
            if (!res.data.data.pointEnough) {
              this.openDialogStep1 = false
            }
          })
          .catch((e) => {
            alert('系統異常')
            console.log(e)
          })
      }
    },
    exchangeLottery() {
      /** 取得虛擬獎項 */
      this.nowItem = this.getVirtualReward()
      console.log(this.nowItem)
      let item = this.getVirtualReward()

      if (item.text === '立即兌換') {
        this.openDialogStep0 = true
        let url =
          process.env.VUE_APP_APIUrl + 'api/v1/auth/rewards/verify-user-points'
        let data = {
          rewardId: item.id
        }
        this.axios
          .post(url, data, {
            headers: {
              Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
            }
          })
          .then((res) => {
            if (!res.data.data.pointEnough) {
              this.openDialogStep0 = false
            }
          })
          .catch((e) => {
            alert('系統異常')
            console.log(e)
          })
      }
    },
    confirmInfo() {
      this.openDialogStep1 = false
      this.openDialogStep2 = true
    },
    cancelExchange() {
      this.openDialogStep0 = false
      this.openDialogStep1 = false
    },
    confirmExchange() {
      if (!this.clicked) {
        this.clicked = true
        console.log(this.address)
        let url = process.env.VUE_APP_APIUrl + 'api/v1/auth/rewards/claim'
        let data = {
          rewardId: this.nowItem.id,
          address: this.address
        }
        this.axios
          .post(url, data, {
            headers: {
              Authorization: 'Bearer ' + window.localStorage.getItem('jwt')
            }
          })
          .then((res) => {
            console.log(res)
            this.clicked = false
            this.openDialogStep0 = false
            this.openDialogStep2 = false
            this.openDialogStep3 = true
          })
          .catch((e) => {
            console.log(e)
            alert('兌換失敗 請洽管理員')
            this.openDialogStep2 = false
          })
      }
    },
    closeConfirm() {
      this.openDialogStep3 = false
      this.getdata()
    },
    openPointHistory() {
      this.pointHistoryDialog = true
    },
    closePointHistory() {
      this.pointHistoryDialog = false
    },
    openDetail(item) {
      this.nowItem = item
      this.openDetailDialog = true
    },
    closeDetail() {
      this.openDetailDialog = false
    }
  }
}
</script>

<style lang="scss">
.exchange {
  .points {
    width: 100%;
    background-color: #efefef;
    border: 1px solid #888888;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 12px 15px;

    .points_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 0;
      }

      .points_text {
        color: #333;
        font-weight: 400;
      }

      &.total {
        align-items: flex-end;

        .points_text {
          font-weight: 500;

          &.point {
            color: #c3131b;

            span {
              font-size: 22px;
              font-weight: 700;
            }
          }
        }
      }
    }

    &_history {
      color: rgb(0, 132, 255);
      text-decoration: underline;
    }
  }

  .exchange_alert {
    margin-bottom: 4em;
    margin-top: 3em;
    padding: 1em 2em;
    border: solid 3px #fff;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
  }

  .exchange_filter {
    position: absolute;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.7);
    top: 2em;
    bottom: -1vh;
    left: -1vh;
    right: -1vh;
    z-index: 999;
    padding: 0 3vh;
    display: flex;
    align-items: center;
  }

  .exchange_text {
    background-color: black;
    font-size: 1.2em;
    margin: 0 auto;
    font-weight: bold;
    padding: 0.2em 1em;
    border: 3px solid #fff;
    z-index: 99999;
    text-align: center;
  }

  .exchange_list {
    margin-top: 25px;
    width: 100%;
    position: relative;

    &_item {
      display: flex;
      //background-color: #222;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &_img {
        //background-color: #fff; // 圖片白色背景
        width: 145px; // 41.5%
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
        }
      }

      &_content {
        flex: 1;
        padding: 0px 28px;

        .exchange_btn {
          margin-top: 10px;

          &.v-btn {
            font-size: 16px !important;
          }
        }

        p {
          text-align: left;
        }
      }

      &_title {
        font-weight: 500;
      }

      &_detail {
        font-size: 14px;
        text-decoration: underline;
      }

      &_row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 14px;

        p {
          font-size: 14px;
          font-weight: lighter;
          color: #cccccc;
        }

        span {
          color: white;
          font-size: 22px;
          font-weight: bold;
        }

        .unit {
          color: white;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  &_notice {
    margin: 25px 0;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
  }
}

.exchange-dialog {
  &_content {
    margin: 10px 0 30px;

    span {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &_title {
    font-weight: 500;
    margin-bottom: 6px !important;
  }
}

.point-history {
  &_list {
    font-size: 14px;
    color: #333;
    margin: 10px 0 30px;
    width: calc(100% + 50px);
  }

  &_item {
    padding: 10px 25px;
    width: 100%;
    border-top: 1px solid rgba(#333, 0.3);

    > p {
      text-align: left !important;
    }

    &_row {
      display: flex;
      justify-content: space-between;
    }

    &_text {
      text-align: right !important;
      font-weight: bold;
      width: 132px;
    }

    &:last-child {
      border-bottom: 1px solid rgba(#333, 0.3);
    }
  }
}

.v-input {
  &.exchange_input {
    width: 267px;
    height: 44px;
    margin-bottom: 25px !important;
    border-radius: 8px;

    .v-input__control {
      min-height: 44px !important;
      border: 1px solid #c3131b;

      .v-input__slot {
        background-color: rgba(0, 0, 0, 0.05) !important;

        input {
          color: #333;
          font-weight: 700;
        }
      }
    }
  }
}

.line {
  background: #555555;
  height: 1px;
  width: 100%;
  margin: 20px 0px;
}

.testt {
  color: red !important;
}
</style>
